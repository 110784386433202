<template>
  <v-row>
    <div class="ml-3 mt-5">
      <h2>Subscriptions</h2>
    </div>

    <v-col
      cols="12"
      class="white mt-3"
    >
      <h2>Placeholder</h2>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
  },
  setup() {
  },
}
</script>
